import React, { Fragment } from "react";
const ExploreProjects = React.lazy(() =>
  import("../../views/exploreProjects/ExploreProjects")
);
const createProject = React.lazy(() =>
  import("../../views/createProject/createProject")
);
const editProject = React.lazy(() =>
  import("../../views/editProject/editProject")
);
const myProfile = React.lazy(() => import("../../views/myProfile/myProfile"));
const editProfile = React.lazy(() =>
  import("../../views/editProfile/editProfile")
);
const settings = React.lazy(() => import("../../views/settings/Settings"));
const myProjects = React.lazy(() =>
  import("../../views/myProjects/myProjects")
);
const myInvestment = React.lazy(() =>
  import("../../views/myInvestment/myInvestment")
);
const viewProject = React.lazy(() =>
  import("../../views/viewProject/viewProject")
);
const confirmInvestment = React.lazy(() =>
  import("../../views/confirmInvestment/confirmInvestment")
);
const confirmDonation = React.lazy(() =>
  import("../../views/confirmDonation/confirmDonation")
);

import { Redirect, Route } from "react-router-dom";
import { getItem } from "../../localStorage/LocalStorage";

const PrivateRoutes = () => {
  const user = getItem("userid");
  return (
    <Fragment>
      {user ? (
        <Fragment>
          <Route path="/app/projects" component={ExploreProjects} />
          <Route path="/app/view-project/:id" component={viewProject} />
          <Route path="/app/create-project" component={createProject} />
          <Route path="/app/edit-project/:id" component={editProject} />
          <Route path="/app/my-profile" component={myProfile} />
          <Route path="/app/edit-profile" component={editProfile} />
          <Route path="/app/settings" component={settings} />
          <Route path="/app/my-projects" component={myProjects} />
          <Route path="/app/my-investment" component={myInvestment} />
          <Route path="/app/confirm-investment" component={confirmInvestment} />
          <Route path="/app/confirm-donation" component={confirmDonation} />
        </Fragment>
      ) : (
        <Redirect to="/auth/login" />
      )}
    </Fragment>
  );
};

export default PrivateRoutes;
