import React, {useContext, useState} from "react";

import "./Search.css";
import Input from "../Input/Input.module";
import Button from "../Button/Button.module";

import * as Icon from "react-bootstrap-icons";
import {SearchContext} from "../../context/Search";
import {useHistory} from "react-router-dom";
import {getItem} from "../../localStorage/LocalStorage";

const Search = ({ width, height }) => {
    let history = useHistory();

    const userId = getItem("userid");

    const [state, dispatch] = useContext(SearchContext);
    const [searchText, setSearchText] = useState(state.search.projects || "");

    const changeSearchTerm = () => {
        const searchTerm = { ...state.search, projects: searchText };

        updateSearchCriteria(searchTerm);
        navigateToProjects();
        // filterSearchResults(searchTerm);
    };

    const updateSearchCriteria = (payloadData) => {
        dispatch({ type: "SEARCH_TEXT", payload: payloadData });
    };

    const navigateToProjects = () => {
        history.push(userId ? "/app/projects" : "/projects");
    };

  return (
    <div className="search-wrapper">
      <Input
        placeholder="Search..."
        valueName="Search"
        type="string"
        width={width}
        height={height}
        name={"search"}
        value={state.search.projects}
        onChange={(e) => setSearchText(e.target.value)}
      />

      <Button className="search-btn" onClick={changeSearchTerm}>
        <Icon.Search size={12} />
      </Button>
    </div>
  );
};

export default Search;
