import React, { Fragment } from "react";
import { Route } from "react-router-dom";

const ExploreProjects = React.lazy(() =>
  import("../../views/exploreProjects/ExploreProjects")
);
const LandingPage = React.lazy(() => import("../../views/landing/LandingPage"));
const viewProject = React.lazy(() =>
  import("../../views/viewProject/viewProject")
);

const PublicRoutes = () => {
  return (
    <Fragment>
      <Route exact path="/" component={LandingPage} />
      <Route exact path="/projects" component={ExploreProjects} />
      <Route exact path="/view-project/:id" component={viewProject} />
    </Fragment>
  );
};

export default PublicRoutes;
