import React, { Suspense } from "react";
import BaseRoutes from "../src/routes/index";
import { Grid } from "react-loader-spinner";
import "./translations/i18n";
import {SearchProvider} from "./context/Search";

const CameroonApp = () => {
  return (
    <Suspense
      fallback={
        <div className="loader">
          <Grid color="#ef4565" height={80} width={80} />
        </div>
      }
    >
        <SearchProvider>
      <BaseRoutes />
        </SearchProvider>
    </Suspense>
  );
};

export default CameroonApp;
