import React from "react";
import TopHeader from "../components/TopHeader/TopHeader.module";
import Footer from "../components/Footer/Footer.module";
import PublicRoutes from "../routes/public";
import { Container } from "react-bootstrap";
import Header from "../components/Header/Header.module";

const PublicLayout = (props) => {
    const path = props.location.pathname.includes('/projects') || props.location.pathname.includes('/view-project');

  return (
    <>
      <TopHeader page="public" />
        {!path && <Header />}
        {/*{props.location.pathname !== '/projects' && props.location.pathname !== '/view-project' && <Header />}*/}
      <Container>
        <PublicRoutes />
      </Container>
      <Footer page="public" />
    </>
  );
};

export default PublicLayout;
