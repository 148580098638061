import React, { Fragment } from "react";
import { Route } from "react-router-dom";

const LoginPage = React.lazy(() => import("../../views/login/LoginPage"));
const RegisterPage = React.lazy(() =>
  import("../../views/register/RegisterPage")
);
const ForgotPasswordPage = React.lazy(() =>
  import("../../views/forgotPassword/ForgotPasswordPage")
);

const AuthRoutes = () => {
  return (
    <Fragment>
      <Route path="/auth/login" component={LoginPage} />
      <Route path="/auth/register" component={RegisterPage} />
      <Route path="/auth/forgot" component={ForgotPasswordPage} />
    </Fragment>
  );
};

export default AuthRoutes;
