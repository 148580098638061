//AUTH
export const SIGNIN = "SIGNIN";
export const SIGNUP = "SIGNUP";
export const SIGNOUT = "SIGNOUT";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const LOADING_END = "LOADING_END";
export const LOADING_START = "LOADING_START";

export const CREATE_PROJECT = "CREATE_PROJECT";
export const EDIT_PROJECT = "EDIT_PROJECT";
export const DELETE_PROJECT = "DELETE_PROJECT";

export const EDIT_PERSONAL_INFO = "EDIT_PERSONAL_INFO";
export const EDIT_ADDITIONAL_INFO = "EDIT_ADDITIONAL_INFO";
export const SORT_PROJECTS = "SORT_PROJECTS";
export const SEARCH_PROJECTS = "SEARCH_PROJECTS";
export const EXPLORE_PROJECTS = "EXPLORE_PROJECTS";
export const POPULATE_EXPLORE_PROJECTS = "POPULATE_EXPLORE_PROJECTS";
export const FETCH_MY_PROJECTS = "FETCH_MY_PROJECTS";
export const POPULATE_MY_PROJECTS = "POPULATE_MY_PROJECTS";
export const FETCH_SAVED_PROJECTS = "FETCH_SAVED_PROJECTS";
export const POPULATE_SAVED_PROJECTS = "POPULATE_SAVED_PROJECTS";
export const FETCH_LIKED_PROJECTS = "FETCH_LIKED_PROJECTS";
export const POPULATE_LIKED_PROJECTS = "POPULATE_LIKED_PROJECTS";
export const FETCH_RECOMMENDED_PROJECTS = "FETCH_RECOMMENDED_PROJECTS";
export const POPULATE_RECOMMENDED_PROJECTS = "POPULATE_RECOMMENDED_PROJECTS";
export const FETCH_PROJECT_DETAIL = "FETCH_PROJECT_DETAIL";
export const POPULATE_PROJECT_DETAIL = "POPULATE_PROJECT_DETAIL";
export const LIKE_PROJECT = "LIKE_PROJECT";
export const SAVE_PROJECT = "SAVE_PROJECT";
export const SHARE_PROJECT = "SHARE_PROJECT";
export const FETCH_PROJECT_ENUMS = "FETCH_PROJECT_ENUMS";
export const POPULATE_PROJECT_ENUMS = "POPULATE_PROJECT_ENUMS";

export const FETCH_CATEGORIES = "FETCH_CATEGORIES";
export const POPULATE_CATEGORIES = "POPULATE_CATEGORIES";

export const FETCH_USER_PROFILE = "FETCH_USER_PROFILE";
export const POPULATE_USER_PROFILE = "POPULATE_USER_PROFILE";
export const FETCH_USER_ACCOUNT_INFO = "FETCH_USER_ACCOUNT_INFO";
export const POPULATE_USER_ACCOUNT_INFO = "POPULATE_USER_ACCOUNT_INFO";
export const SEND_SUBSCRIPTION_INFO = "SEND_SUBSCRIPTION_INFO";
export const SEND_ACCOUNT_STATUS_INFO = "SEND_ACCOUNT_STATUS_INFO";

export const UPLOAD_PROJECT_PHOTO = "UPLOAD_PROJECT_PHOTO";
export const EDIT_UPLOAD_PROJECT_PHOTO = "EDIT_UPLOAD_PROJECT_PHOTO";
